export const formeSocialeList = [
  {
    "text": "SAS",
    "value": "SAS"
  },
  {
    "text": "SASU",
    "value": "SASU"
  },
  {
    "text": "Entreprise individuelle (EI)",
    "value": "EI"
  },
  {
    "text": "EURL",
    "value": "EURL"
  },
  {
    "text": "SARL",
    "value": "SARL"
  },
  {
    "text": "SCI",
    "value": "SCI"
  },
  {
    "text": "SELAS",
    "value": "SELAS"
  },
  {
    "text": "SELASU",
    "value": "SELASU"
  },
  {
    "text": "SELARLU",
    "value": "SELARLU"
  },
  {
    "text": "SELARL",
    "value": "SELARL"
  },
  {
    "text": "SA",
    "value": "SA"
  },
  {
    "text": "SCS",
    "value": "SCS"
  },
  {
    "text": "SNC",
    "value": "SNC"
  },
  {
    "text": "SCA",
    "value": "SCA"
  }
]
