export const qualiteList = [
  {
    "text": "Associé",
    "value": "ASSOCIE"
  },
  {
    "text": "Dirigeant",
    "value": "DIRIGEANT"
  },
  {
    "text": "Mandataire",
    "value": "MANDATAIRE"
  }
]
