export const procedureCollectiveList = [
  {
    "text": "Non",
    "value": "NON"
  },
  {
    "text": "Conciliation",
    "value": "CONCILIATION"
  },
  {
    "text": "Sauvegarde",
    "value": "SAUVEGARDE"
  },
  {
    "text": "Redressement judiciaire",
    "value": "REDRESSEMENT"
  }
]
